define(['app', '$navigator', 'qrcode'], function (app, $navigator, QRious) {

  const manyToManyReferrals = () => {
    const component = {};

    const _config = {
      selectors: {
        component: '[data-component=manyToManyReferrals]',
        radio: '[data-referrals-options]',
        qrcodeURL: 'data-referral-qr-url',
        crossSiteQrcodeURL: 'data-referral-qr-url-cross-site',
        socialIcons: '[data-referral-social-icon]',
        socialIconsAttribute: 'data-referral-social-icon',
        socialShareEmail: {
          modalInput: '[data-referral-email-input]',
          send: '[data-referral-email-send]',
          errorWrapper: '[data-referral-email-error]',
          failWrapper: '[data-referral-failed-send]',
          error: '[data-referral-email-error-message]',
          fail: '[data-referral-email-fail-message]',
          success: '[data-referral-email-success-message]',
          successWrapper: '[data-referral-email-success]',
          successMessage: 'data-referral-email-success-email-sent',
          errorCaptchaMessage: 'data-referral-email-error-invalid-captcha',
          errorInvalidInput: 'data-referral-email-error-invalid-input',
          errorInternalMessage: 'data-referral-email-error-internal'
        },
        referralCopyInput: '[data-referrals-copy-code]',
        socialShareCopyLink: '[data-referral-click-link]',
        socialShareCopyLinkInput: '[data-referral-social-copy-link]',
        hasCopiedSocialNetworkLink: '[data-referral-has-copied]',
        hasCopiedCode: '[data-referral-has-copied-code]',
        stepTwoSubHeading: '.manyToManyReferrals_stepTwo_subHeading'
       
      },
      classes: {
        showClass: 'manyToManyReferrals_showElement',
        hideClass: 'manyToManyReferrals_hideElement'
      },
      defaults: {
        navigator: $navigator.userAgent || $navigator.vendor || window.opera,
        mobileOS : 'unknown'
      },
      elements : {
        radioButtonsArray: null
      }
    };

    const _init = element => {
      component.element = element;
      component.bind();
      component.stepTwoMessage();
      return component;
    };


    const _bind = () => {
      component.config.elements.radioButtonsArray = [...component.element.querySelectorAll(component.config.selectors.radio)];
      component.emailErrorElementWrapper = component.element.querySelector(component.config.selectors.socialShareEmail.errorWrapper);
      component.emailFailElementWrapper = component.element.querySelector(component.config.selectors.socialShareEmail.failWrapper);
      component.emailSuccessElementWrapper = component.element.querySelector(component.config.selectors.socialShareEmail.successWrapper);
      component.setupSendButtonEmail();
      component.setupCopyReferrals();
      component.setupQRCode();
      component.setupRadioChangeListener();
      component.identifyMobileOS();
    };

    const _getEndpointPathFromRadio = () => {
      const radioButtons = component.element.querySelectorAll(component.config.selectors.radio);
      let endpointPath = '/sendInternalReferralEmail.awesome';

      Array.from(radioButtons).map(el => {
        if (el.checked && el.value === 'newsite') {
          endpointPath = '/sendExternalReferralEmail.awesome';
        }
      });

      return endpointPath;
    };

    const _getReferralUrlFromRadio = () => {
      const radioButtons = component.element.querySelectorAll(component.config.selectors.radio);
      let referralUrl = component.element.getAttribute(component.config.selectors.crossSiteQrcodeURL);
      let shareButtonLink = component.element.querySelector(component.config.selectors.socialShareCopyLinkInput);
      Array.from(radioButtons).map(el => {
        if (el.checked && el.value === 'newsite') {
          referralUrl = component.element.getAttribute(component.config.selectors.qrcodeURL);
          shareButtonLink.setAttribute("value", referralUrl);
        }
        else {
          shareButtonLink.setAttribute("value", referralUrl);
        }
      });

      return referralUrl;
    };

    const _setupRadioChangeListener = () => {
      
      if (component.config.elements.radioButtonsArray.length > 0) {
        component.config.elements.radioButtonsArray.forEach(element => {
          component.addListener(element, 'change', component.radioButtonEventHandler)
        }); 
        
      }
    };

    const _setupSendButtonEmail = () => {
      const socialShareEmailSend = component.element.querySelector(component.config.selectors.socialShareEmail.send);

      if (socialShareEmailSend) {
        component.addListener(socialShareEmailSend, 'click', component.sendReferralEmail);
      }
    };

    const _setupCopyReferrals = () => {
      const referralInput = component.element.querySelector(component.config.selectors.referralCopyInput);
      const socialNetworkCopyLinkButton = component.element.querySelector(component.config.selectors.socialShareCopyLink);

      if (referralInput) {
        component.addListener(referralInput, 'click', component.copyReferral);
      }

      if(socialNetworkCopyLinkButton) {
        component.addListener(socialNetworkCopyLinkButton, 'click', component.socialNetworkCopyLink);
      }
    };

    const _copyReferral = () => {
      const referralCopyInput = component.element.querySelector(component.config.selectors.referralCopyInput);
      const hasCopied = component.element.querySelector(component.config.selectors.hasCopiedCode);
      referralCopyInput.select();
      component.copyToClipboard(hasCopied)
    };

    const _showCopyAlert = (hasCopied) => {
      hasCopied.classList.remove(component.config.classes.hideClass);

      setTimeout(() => {
        hasCopied.classList.add(component.config.classes.hideClass);
      }, 2000)
    };

    const _socialNetworkCopyLink = () => {
      const socialNetworkCopyLinkInput = component.element.querySelector(component.config.selectors.socialShareCopyLinkInput);
      const hasCopied = component.element.querySelector(component.config.selectors.hasCopiedSocialNetworkLink);
      socialNetworkCopyLinkInput.select();
      component.copyToClipboard(hasCopied);
    };

    const _copyToClipboard = (hasCopied) => {
      try {
        document.execCommand('copy');
        component.showCopyAlert(hasCopied);
      } catch (err) {
        console.error('Unable to copy selected text');
      }
    };

    const _setupQRCode = () => {
      new QRious({
        element: document.getElementById('manyToManyReferrals_qrCode'),
        value: component.getReferralUrlFromRadio(),
        size: 150
      });
    };

    const _stepTwoMessage = () => {
      const stepTwoMessages = component.element.querySelectorAll(component.config.selectors.stepTwoSubHeading);

      if (stepTwoMessages.length < 1) return false;

      stepTwoMessages.forEach(textBlock => { textBlock.style.display = 'none' });
      stepTwoMessages[component.config.elements.radioButtonsArray.findIndex(checkbox => checkbox.checked)].style.display = 'block';
    };

    const _radioButtonEventHandler = () => {
      component.setupQRCode();
      component.stepTwoMessage();
    };

    const _sendReferralEmail = () => {
      const socialShareEmailModalInput = component.element.querySelector(component.config.selectors.socialShareEmail.modalInput);

      if (socialShareEmailModalInput) {
        const emailList = socialShareEmailModalInput.value.split(/[ ,]+/).filter(Boolean) // removes  0, -0, null, false, NaN, undefined, ""
          .filter(component.validateEmail); // remove invalid email addresses

        let GOOGLE_RECAPTCHA_RESPONSE = component.element.querySelector('#g-recaptcha-response');
        GOOGLE_RECAPTCHA_RESPONSE = GOOGLE_RECAPTCHA_RESPONSE ? GOOGLE_RECAPTCHA_RESPONSE.value : '0';

        const sendData = {
          referralEmailsForm: emailList,
          googleCaptchaToken: GOOGLE_RECAPTCHA_RESPONSE
        };

        const emailEndpointPath = component.getEndpointPathFromRadio();

        component.sendJsonAjaxPost(sendData, emailEndpointPath);
      }
    };

    const _sendJsonAjaxPost = (sendData, emailEndpointPath) => {
      app.ajax.post({
        url: window.location.protocol + '//' + window.location.hostname + emailEndpointPath,
        send: JSON.stringify(sendData),
        requestHeader: {
          header: 'Content-Type',
          value: 'application/json'
        },
        success: component.referralSentSuccessHandler,
        error: component.referralSentErrorHandler
      });
    };

    const _referralSentSuccessHandler = responseData => {
      const emailErrorElement = component.element.querySelector(component.config.selectors.socialShareEmail.error);
      const emailSuccessElement = component.element.querySelector(component.config.selectors.socialShareEmail.success);

      component.hideEmailAlert(component.emailErrorElementWrapper);
      component.hideEmailAlert(component.emailFailElementWrapper);
      responseData = responseData !== null && responseData !== '' ? responseData : '{}';
      responseData = JSON.parse(responseData);

      const emailCaptchaErrorMessage = component.element.getAttribute(component.config.selectors.socialShareEmail.errorCaptchaMessage);
      const emailInvalidInputErrorMessage = component.element.getAttribute(component.config.selectors.socialShareEmail.errorInvalidInput);
      const emailInternalErrorMessage = component.element.getAttribute(component.config.selectors.socialShareEmail.errorInternalMessage);
      const emailSuccessMessage = component.element.getAttribute(component.config.selectors.socialShareEmail.successMessage);

      switch (responseData.response) {
        case 'INVALID_CAPTCHA':
          emailErrorElement.innerText = emailCaptchaErrorMessage;
          component.showEmailAlert(component.emailErrorElementWrapper);
          break;

        case 'errorInputReferrals':
          emailErrorElement.innerText = emailInvalidInputErrorMessage;
          component.showEmailAlert(component.emailErrorElementWrapper);
          break;
        case 'internalError':
          emailErrorElement.innerText = emailInternalErrorMessage;
          component.showEmailAlert(component.emailErrorElementWrapper);
          break;
        case 'emailSent':
          emailSuccessElement.innerText = emailSuccessMessage;
          component.showEmailAlert(component.emailSuccessElementWrapper);
          break;
      }

      // everything went well ---> close modal, reset the input
      const socialShareEmailModalInput = component.element.querySelector(component.config.selectors.socialShareEmail.modalInput);
      if (socialShareEmailModalInput) {
        socialShareEmailModalInput.value = '';
      }

    };

    const _showEmailAlert = (element) => {
      element.classList.remove(component.config.classes.hideClass);
    };

    const _hideEmailAlert = (element) => {
      element.classList.add(component.config.classes.hideClass);
    }

    const _referralSentErrorHandler = () => {
      const emailFailElement = component.element.querySelector(component.config.selectors.socialShareEmail.fail);
      emailFailElement.innerText = 'Failed to send email';

      component.showEmailAlert(component.emailFailElementWrapper);
    };

    const _validateEmail = (email) => {
      const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    };

    const _identifyMobileOS = () => {
      component.config.defaults.mobileOS = ((component.config.defaults.navigator.match(/iPad/i) || component.config.defaults.navigator.match(/iPhone/i) || component.config.defaults.navigator.match(/iPod/i)) || (component.config.defaults.navigator.match(/Android/i)) || 'unknown');
    };

    const _addListener = (element, event, callBack) => {
      if (element && event && callBack) {
        element.addEventListener(event, callBack);
      }
    };

    component.init = _init;
    component.config = _config;
    component.bind = _bind;
    component.showCopyAlert = _showCopyAlert;
    component.copyReferral = _copyReferral;
    component.setupQRCode = _setupQRCode;
    component.socialNetworkCopyLink = _socialNetworkCopyLink;
    component.identifyMobileOS = _identifyMobileOS;
    component.sendReferralEmail = _sendReferralEmail;
    component.validateEmail = _validateEmail;
    component.referralSentSuccessHandler = _referralSentSuccessHandler;
    component.referralSentErrorHandler = _referralSentErrorHandler;
    component.copyToClipboard = _copyToClipboard;
    component.showEmailAlert = _showEmailAlert;
    component.hideEmailAlert = _hideEmailAlert;
    component.addListener = _addListener;
    component.setupSendButtonEmail = _setupSendButtonEmail;
    component.sendJsonAjaxPost = _sendJsonAjaxPost;
    component.getEndpointPathFromRadio = _getEndpointPathFromRadio;
    component.getReferralUrlFromRadio = _getReferralUrlFromRadio;
    component.setupCopyReferrals = _setupCopyReferrals;
    component.setupRadioChangeListener = _setupRadioChangeListener;
    component.radioButtonEventHandler = _radioButtonEventHandler;
    component.stepTwoMessage = _stepTwoMessage;
    return component;
  };

  return manyToManyReferrals;
});
